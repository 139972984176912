export const environment = {
  test: 'dev',
  ANOTAAI_ADMIN_URL: 'https://preprod-admin-front.anota-ai.com',
  redirectUrl: 'https://staging-register-frontend.anota-ai.com',
  BACKEND_URL: 'https://staging-freemium-api.anota-ai.com',
  CRM_API_URL: 'https://staging-crm-api.anota-ai.com',
  UUID_KEY: '6369373afb084793432f927d',
  INBOUND_URL: 'https://staging-inbound.anota-ai.com',
  ANOTAAI_RESPONDE_DOWNLOAD_URL: 'https://staging-app.anota-ai.com/download-app/anotaai-desktop',
  GOOGLE_PLAY_APP_URL: 'https://play.google.com/store/apps/details?id=ai.anota.android',
  INSTALL_APP_REDIRECT_URL: 'https://instalar.anota.ai/aplicativo',
  ONE_LINK_REDIRECT_URL: 'https://encurta.anota.ai/aplicativo',
  gtmId: 'GTM-MKQBRXH',
  BOT_MOBILE_URL: 'https://staging-bot-mobile.anota-ai.com',
  ANOTAAI_DESKTOP_URL: 'http://127.0.0.1:420',
  AMPLITUDE_KEY: '2e0c793cc024daa3bfcab39bfc2a2439',
  ANOTAAI_RESPONDE_PROTOCOL: 'anotaaistg',
};
